<template>
    <b-modal
      v-model="data.showModal"
      size="xl"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div class="flex gap-1">
            <div>Edit group</div>
            <div v-if="group">- {{ group.group_name }}</div>
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div class="grid lg:grid-cols-2 gap-2">
        <div>
          <table class="table table-google">
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody v-if="group">
              <tr>
                <td class="row-fit text-nowrap">ID</td>
                <td>{{ group.id | format }}</td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Group Key</td>
                <td>{{ group.group_key }}</td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Group Name</td>
                <td>
                  <b-form-input
                    v-model="group.group_name"
                    placeholder="Group Name"
                    size="sm"
                    autocomplete="off"
                    trim
                    autofocus
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Extra 1</td>
                <td>
                  <b-form-input
                    v-model="group.group_extra1"
                    placeholder="Extra 1"
                    size="sm"
                    autocomplete="off"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Extra 2</td>
                <td>
                  <b-form-input
                    v-model="group.group_extra2"
                    placeholder="Extra 2"
                    size="sm"
                    autocomplete="off"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Option Sort</td>
                <td>
                  <button-select
                    v-model="group.option_sort"
                    :options="options.option_sort"
                    size="sm"
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Option Prefix</td>
                <td>
                  <b-form-input
                    v-model="group.option_prefix"
                    placeholder="Option Prefix"
                    size="sm"
                    autocomplete="off"
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Option Suffix</td>
                <td>
                  <b-form-input
                    v-model="group.option_suffix"
                    placeholder="Option Suffix"
                    size="sm"
                    autocomplete="off"
                  ></b-form-input>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="2">
                  <div v-if="isLoading">Loading..</div>
                  <div v-else>Error</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex flex-col">
          <table
            v-if="group"
            class="table table-google"
          >
            <thead>
              <tr>
                <th v-if="isManual"><!-- drag handle --></th>
                <th>Option</th>
                <th v-if="filters.extra_field === 'short'" class="text-nowrap">
                  Short
                </th>
                <th v-if="extra1 && filters.extra_field === 'extra'" class="text-nowrap">
                  {{ group.group_extra1 }}
                </th>
                <th v-if="extra2 && filters.extra_field === 'extra'" class="text-nowrap">
                  {{ group.group_extra2 }}
                </th>
                <th><!-- Actions --></th>
              </tr>
            </thead>

            <draggable
              tag="tbody"
              :list="group.options"
              handle=".drag-handle"
            >
              <tr v-for="option in group.options" :key="option.guid">
                <td
                  v-if="isManual"
                  class="drag-handle row-fit"
                >
                  <div class="flex justify-center">
                    <i class="fas fa-bars"></i>
                  </div>
                </td>

                <td>
                  <b-input-group
                    :prepend="group.option_prefix"
                    size="sm"
                  >
                    <b-form-input
                      v-model="option.option_value"
                      placeholder="Value"
                      size="sm"
                      trim
                      autocomplete="off"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-input-group-text v-if="group.option_suffix">
                        {{ group.option_suffix }}
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </td>
                <td v-if="filters.extra_field === 'note'">
                  <b-form-input
                    v-model="option.option_note"
                    placeholder="Note"
                    size="sm"
                    trim
                    autocomplete="off"
                  ></b-form-input>
                </td>
                <td v-if="filters.extra_field === 'short'">
                  <b-form-input
                    v-model="option.option_short"
                    placeholder="Short"
                    size="sm"
                    trim
                    autocomplete="off"
                    :class="{ 'border-danger': shortDuplicates.includes(option.option_short) }"
                  ></b-form-input>
                </td>
                <td v-if="extra1 && filters.extra_field === 'extra'">
                  <b-form-input
                    v-model="option.option_extra1"
                    placeholder="Extra 1"
                    size="sm"
                    trim
                    autocomplete="off"
                  ></b-form-input>
                </td>
                <td v-if="extra2 && filters.extra_field === 'extra'">
                  <b-form-input
                    v-model="option.option_extra2"
                    placeholder="Extra 2"
                    size="sm"
                    trim
                    autocomplete="off"
                  ></b-form-input>
                </td>
                <td class="row-fit">
                  <b-btn
                    size="sm"
                    variant="danger"
                    :disabled="isLoading"
                    @click="deleteOption(option)"
                    title="Delete option"
                  >
                    &times;
                  </b-btn>
                </td>
              </tr>
            </draggable>
          </table>

          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading"
              @click="createOption"
            >
              Add new option
            </b-btn>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between items-center">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-8 items-center">
            <div
              v-if="filters.extra_field === 'short' && shortDuplicates.length > 0"
            >
              <span>Duplicates: </span>
              <span class="text-red-500 font-bold">{{ shortDuplicates.join(', ') }}</span>
            </div>

            <div>
              <button-select
                v-model="filters.extra_field"
                :options="options.extra_field"
                size="sm"
              ></button-select>
            </div>

            <div class="flex gap-2">
              <b-btn
                size="sm"
                variant="danger"
                :disabled="isLoading"
                @click="onDelete"
              >
                Delete
              </b-btn>

              <b-btn
                size="sm"
                variant="white"
                :disabled="isLoading"
                @click="onClose"
              >
                Close
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  generateGuid,
  getArrayDuplicates,
  hasWhitespace,
} from '@/helpers';
import draggable from 'vuedraggable';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'SelectEditor',
  components: {
    ButtonSelect,
    draggable,
  },
  props: {
    data: Object,
  },
  computed: {
    shortDuplicates() {
      const shorts = this.group?.options.map((option) => option.option_short);
      if (shorts === undefined) return [];
      const duplicates = getArrayDuplicates(shorts);
      return duplicates;
    },
    extra1() {
      return this.group?.group_extra1.length > 0;
    },
    extra2() {
      return this.group?.group_extra2.length > 0;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    isManual() {
      return this.group?.option_sort === false;
    },
    canSave() {
      if (!this.group) return false;
      if (this.group.group_key.length === 0) return false;
      if (hasWhitespace(this.group.group_key)) return false;
      if (this.group.group_name.length === 0) return false;
      return true;
    },
    sendOptions() {
      if (!this.group) {
        return null;
      }
      const sendOptions = this.group.options.map((option, index) => ({
        id: option.id,
        option_index: index + 1,
        option_value: option.option_value,
        option_short: option.option_short,
        option_note: option.option_note,
        option_extra1: option.option_extra1,
        option_extra2: option.option_extra2,
      }));
      return sendOptions;
    },
    sendData() {
      if (!this.group) {
        return null;
      }
      const sendData = {
        ...this.group,
        options: this.sendOptions,
      };
      return sendData;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {
        extra_field: 'note',
      },
      options: {
        option_sort: [
          { text: 'Automatic', value: true },
          { text: 'Manual', value: false },
        ],
        extra_field: [
          { text: 'Note', value: 'note' },
          { text: 'Short', value: 'short' },
          { text: 'Extra', value: 'extra' },
        ],
      },
      group: null,
    };
  },
  methods: {
    fetchGroup() {
      this.loadingCount++;
      this.$http
        .get(`/select/groups/${this.data.groupKey}`)
        .then((res) => {
          res.body.group.options.forEach((option) => {
            option.guid = generateGuid();
          });
          this.group = res.body.group;
        })
        .catch((err) => {
          this.$error.toast(`Failed to fetch group: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateGroup() {
      this.loadingCount++;
      this.$http
        .put(`/select/groups/${this.data.groupKey}`)
        .send({ data: this.sendData })
        .then((res) => {
          this.$emit('updated', res.body.group);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update group: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.$store.dispatch('fetchDropdowns');
        });
    },
    deleteGroup() {
      if (!confirm('Do you really wish to delete this group?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/select/groups/${this.data.groupKey}`)
        .then(() => {
          this.$emit('deleted', this.data.groupKey);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete group: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.$store.dispatch('fetchDropdowns');
        });
    },
    createOption() {
      this.group.options.push({
        id: null,
        guid: generateGuid(),
        option_index: null,
        option_value: '',
        option_short: '',
        option_note: '',
        option_extra1: '',
        option_extra2: '',
      });
    },
    deleteOption(deleteOption) {
      if (!confirm(`Do you really wish to delete '${deleteOption.option_value}' option?`)) {
        return;
      }
      this.group.options = this.group.options.filter((option) => option.guid !== deleteOption.guid);
    },
    fetchAll() {
      this.fetchGroup();
    },
    onSave() {
      this.updateGroup();
    },
    onDelete() {
      this.deleteGroup();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
